import "../styles/main.scss";

// LazyLoad
if (!("IntersectionObserver" in window)) {
	require("intersection-observer-amd");
}
import LazyLoad from "vanilla-lazyload/dist/lazyload.amd";
window.LazyLoad = new LazyLoad();

//// LightBox
//import GLightbox from 'glightbox';
//window.GLightbox = new GLightbox();


let slide = (hash) => {
	document.querySelector(hash).scrollIntoView({
		behavior: 'smooth'
	});
};

document.querySelectorAll('a[href^="#"]').forEach(item => {
	item.addEventListener('click', function (event) {
		if (this.hash) {
			slide(this.hash);
			event.preventDefault();
		}
	});
});

if (location.hash) {
	slide(location.hash);
}
